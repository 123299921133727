.guide-container{
    padding-top: 40px;
    max-width: 80% !important;
    text-align: left;
    letter-spacing: -1.5px;
    font-family: "Cousine", monospace;
    a{
        color: #464646;
        text-decoration: underline !important;
    }
    .col{
        padding-left: 0px;
    }
}