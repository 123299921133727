.post-editor-container {
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    .col {
        padding-left: 0px;
        padding-right: 0px;
        text-align: left;
    }
}
