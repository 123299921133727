.dot {
    height: 3px;
    width: 3px;
    background-color: #bbb;
    border-radius: 50%;
    margin-bottom: 3.5px;
    display: inline-block;
}

.footer-container{
    max-width: 80% !important;
    text-align: left;
    padding-left: 0px;
    letter-spacing: -1.5px;
    font-family: "Cousine", monospace;
    a{
        color: #464644 !important;
    }
}