.thread-page{
    display: flex;
    justify-content: center;
}


.thread-table{
    .badge{
        font-weight: bold !important;
    }
}